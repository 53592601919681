import Home from '../views/Home.vue';
let routerSurface = [
  {
    path: '/',
    name: '',
    redirect: 'index',
    component: Home,
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/index.png',
      name: '首页',
      rules: 1,
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/index/index'),
        name: 'index',
        meta: {
          name: '首页',
          rules: 1,
          form: 1,
        },
      },
    ],
  },
  {
    path: '/goods',
    name: 'goods',
    component: Home,
    redirect: '/goods/goodsList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '商品',
      title: '商品管理',
      rules: 1,
    },
    children: [
      {
        path: '/goods/goodsList',
        component: () => import('@/views/goods/GoodsList'),
        name: 'goods-goodsList',
        meta: {
          name: '商品列表',
          rules: 1,
        },
      },
      {
        path: '/goods/addGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'goods-addGoods',
        meta: {
          name: '添加商品',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/goods/editGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'goods-editGoods',
        meta: {
          name: '编辑商品',
          relation: 'goods-addGoods',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/goods/editGoodsType',
        component: () => import('@/views/goods/AddType'),
        name: 'goods-editGoodsType',
        meta: {
          name: '编辑分类',
          relation: 'goods-addGoods',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/goods/goodsType',
        component: () => import('@/views/goods/GoodsType'),
        name: 'goods-goodsType',
        meta: {
          name: '商品分类',
          rules: 1,
        },
      },
      {
        path: '/goods/addType',
        component: () => import('@/views/goods/AddType'),
        name: 'goods-addType',
        meta: {
          name: '添加分类',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/goods/goodsEvaluate',
        component: () => import('@/views/goods/GoodsEvaluate'),
        name: 'goods-GoodsEvaluate',
        meta: {
          name: '商品评价',
          rules: 1,
        },
      },
      {
        path: '/goods/orderList',
        component: () => import('@/views/goods/OrderList'),
        name: 'goods-orderList',
        meta: {
          name: '订单列表',
          rules: 1,
        },
      },
      {
        path: '/goods/salesRefund',
        component: () => import('@/views/goods/SalesRefund'),
        name: 'goods-salesRefund',
        meta: {
          name: '售后退款',
          rules: 1,
        },
      },
      {
        path: '/goods/salesHandle',
        component: () => import('@/views/goods/SalesHandle'),
        name: 'goods-salesHandle',
        meta: {
          name: '售后处理',
          relation: 'goods-salesRefund',
          rules: 0,
        },
      },
      {
        path: '/goods/orderInfo',
        component: () => import('@/views/goods/OrderInfo'),
        name: 'goods-orderInfo',
        meta: {
          name: '订单信息',
          relation: 'goods-orderList',
          rules: 0,
        },
      },
    ],
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: Home,
    redirect: '/marketing/ActivityList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/extension.png',
      name: '营销',
      title: '营销管理',
      rules: 1,
    },
    children: [
      {
        path: '/marketing/ActivityList',
        component: () => import('@/views/marketing/ActivityList'),
        name: 'marketing-ActivityList',
        meta: {
          name: '活动列表',
          rules: 1,
        },
      },
      {
        path: '/marketing/ActivityAudit',
        name: 'marketing-ActivityAudit',
        component: () => import('@/views/marketing/activityAudit'),
        meta: {
          name: '活动审核',
          rules: 1,
        },
      },
      {
        path: '/marketing/ActivityJoin',
        name: 'marketing-ActivityJoin',
        component: () => import('@/views/marketing/activityJoin'),
        meta: {
          name: '参与列表',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/finance',
    name: 'finance',
    component: Home,
    redirect: '/finance/accounts',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/money.png',
      name: '财务',
      title: '财务管理',
      rules: 1,
    },
    children: [
      {
        path: '/finance/accounts',
        component: () => import('@/views/finance/accounts'),
        name: 'finance-accounts',
        meta: {
          name: '财务对账',
          rules: 1,
        },
      },
      {
        path: '/finance/withdraw',
        name: 'finance-withdraw',
        component: () => import('@/views/finance/withdraw'),
        meta: {
          name: '提现记录',
          rules: 1,
        },
      },
      {
        path: '/finance/withdrawDetail',
        name: 'finance-withdrawDetail',
        component: () => import('@/views/finance/withdrawDetail'),
        meta: {
          name: '提现记录',
          relation: 'finance-withdraw',
          rules: 0,
        },
      },
      {
        path: '/finance/income',
        name: 'finance-income',
        component: () => import('@/views/finance/income'),
        meta: {
          name: '商品收益',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/set',
    name: 'set',
    component: Home,
    redirect: '/set/sourceCenter',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/set1.png',
      name: '设置',
      title: '设置管理',
      rules: 1,
    },
    children: [
      {
        path: '/set/sourceCenter',
        component: () => import('@/views/set/sourceCenter/index00'),
        name: 'set-sourceCenter',
        meta: {
          name: '素材中心',
          rules: 1,
        },
      },
      //  {
      //    path: '/set/address',
      //    name: 'set-address',
      //    component: () => import('@/views/set/Address'),
      //    meta: {
      //      name: '退货地址配置',
      //      rules: 1,
      //    },
      //  },
      {
        path: '/set/freightlist',
        name: 'set-freightlist',
        component: () => import('@/views/set/Freightlist'),
        meta: {
          name: '运费配置',
          rules: 1,
        },
      },
      {
        path: '/set/freight',
        name: 'set-freight',
        component: () => import('@/views/set/Freight'),
        meta: {
          name: '添加运费',
          rules: 0,
          form: 1,
          relation: 'set-freightlist',
        },
      },
      {
        path: '/set/freightEdit',
        name: 'set-freightEdit',
        component: () => import('@/views/set/Freight'),
        meta: {
          name: '编辑运费',
          rules: 0,
          form: 1,
          relation: 'set-freightlist',
        },
      },
      {
        path: '/store/storeRolesList',
        name: 'set-roleList',
        component: () => import('@/views/set/roleList'),
        meta: {
          name: '角色管理',
          rules: 1,
        },
      },
      {
        path: '/store/storeUsersList',
        name: 'set-storeUsersList',
        component: () => import('@/views/set/storeUsersList'),
        meta: {
          name: '店员管理',
          rules: 1,
        },
      },
      {
        path: '/store/addStoreUsers',
        name: 'set-addStoreUsers',
        component: () => import('@/views/set/addStoreUsers'),
        meta: {
          name: '添加店员',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/store/editStoreUsers',
        name: 'set-editStoreUsers',
        component: () => import('@/views/set/addStoreUsers'),
        meta: {
          name: '编辑店员',
          form: 1,
          rules: 0,
          relation: 'set-storeUsersList',
        },
      },
    ],
  },
];
export default routerSurface;
